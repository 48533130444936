<template>
<breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
<div id="maincontent_container">
  <div v-if="error" class="alert alert-danger">
                {{error}}
  </div>

  <div class="pt-4">
      <ul class="nav nav-tabs">
        <li class="nav-link active"><router-link :to="{ name: 'Login'}">Log in</router-link></li>
        <li class="nav-link"><router-link :to="{ name: 'RegisterAccountLink'}">Web Account registration</router-link></li>
        <li class="nav-link"><router-link :to="{ name: 'BecomeReseller'}">Become Reseller</router-link></li>
    </ul>
  </div>
  <div class="row">
        <!-- login left side -->
        <div class="col-md-5 p-4">
            <h3 class="pb-4">Log In to your Web Access Account</h3>
            <p class="text-danger bold">
                <i class="bi bi-exclamation-triangle-fill"></i>
                Please sign in with the <span class="fw-bold">Email Address</span> on file. Or contact your Account Manager for assistance.</p>

            <div v-if="status=='success' && !!message "  class="alert alert-success">
                {{message}}
            </div>
            <div v-if="status=='failed'"  class="alert alert-danger">
                {{message}}
            </div>

            <div v-if="status=='need to verify'">
                <form @submit.prevent="handleVerify">
                    <div class="alert alert-danger">{{message}} &nbsp;&nbsp;&nbsp;<button class="btn btn-danger">Click to Verify Your Email Address</button> </div>
                    <input type="hidden" v-model="username">
                </form>
                
            </div>

            <div v-if="succeedMessage"  class="alert alert-success">
                {{succeedMessage}}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>

            <form @submit.prevent="handleLogin">
                <div class="col">
                    <input type="text" v-model="username" class="form-control" required placeholder="Email address">
                </div>
                <div class="col pt-2">
                    <input type="password" v-model="password" class="form-control" required placeholder="Password">
                </div>
                <div ref="turnstileElement"></div>
                <br/>
                <div><router-link :to="{ name: 'ResetPasswordLink' }">Forgot password?</router-link></div>
                <div><router-link :to="{ name: 'RegisterAccountLink' }">Register a new account?</router-link></div>
                <br/>
                <button v-if="loading == false" class="btn btn-primary btn-lg" id="loginBtn">Login</button>
                <button v-if="loading == true" class="btn btn-primary btn-lg" disabled >
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Login            
                </button>
            </form>
            <br/>
            <p>If you can't log in successfully, please <a href="/support/FAQ#q1.7">click here</a> for help. 
            <br/>Or you may email <a href = "mailto:webmaster@malabs.com">webmaster@malabs.com</a> if you have trouble logging in.</p>
        </div> 
        <!-- login right side  -->
        <login-instruction></login-instruction>                  
    </div>

  
</div>

<div id="google_recaptcha"></div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { getAPI } from '../../utils/axios-api'
import { insertReCaptchaScript, handleReCaptcha, detectBrowse, handleTurnstile, loadTurnstileScript, loadTurnstileElement } from '../../utils/common'
import LoginInstruction from '../../components/login/LoginInstruction.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"

export default {
    props: ['query'],
    components:{LoginInstruction, BreadcrumbPage},
    setup(props, context) {
        const turnstileElement = ref(null)     

        const loading = ref(false)

        const username = ref('')
        const password = ref('')

        const status = ref(null)
        const message = ref(null)

        const router = useRouter()
        const route = useRoute()
        const store = useStore()
        const succeedMessage = ref(null)
        const error = ref(null)

        //breadcrumb
        const breadcrumb_links = ref([])
        breadcrumb_links.value.push({text: "Home", link: "Home"})
        breadcrumb_links.value.push({text: "Login", link: "Login"})

        //show message to user why they need to login
        if (!!route.query.from)  {
            if (route.query.from.includes("/products/details/"))
                error.value="Please log in to view the product details."
            else 
                error.value ="Please login to continue."
        }
        
        if (props.query.message){
            succeedMessage.value = "Your email has been verified, you can login now!"
        }
        if (props.query.resetMessage){
            succeedMessage.value = "Your password has been reset successfully, you can login with your new password now!"
        }

        const handleLogin = async () => {
            
            loading.value = true
            message.value = ''
            status.value = ''
            
            //google reCaptcha
            try {
                //let result = await handleReCaptcha(grecaptcha)
                //let result = {'is_human': false}
                let result = await handleTurnstile(turnstile)
                if (result["is_human"] == true) {
                    //if pass google reCaptcha, then do login
                    let login_result = await store.dispatch('login', {username: username.value, password: password.value})
                    
                    status.value = login_result["status"]
                    message.value = login_result["message"]
                    
                    if (status.value == "success") {
                        //emit the function to parent component to update user information in the menu bar
                        context.emit('updateLogin')

                        //get cart quantity and update it in the menu bar
                        let cart_qty = await store.dispatch('getCartTotalQty')
                        context.emit('updateCartQty', cart_qty)

                        //log access (save this user action into log system)
                        if (store.getters.isCustomer) {
                            let result = await store.dispatch('logAccess', {'action':'login'})
                        }
                        
                        loading.value = false
                        
                        //redirect to the url that comes before login.
                        router.replace(route.query.from || "/")
                        return 0
                    }
                    else {
                        loading.value = false
                        await loadTurnstileElement(turnstile, turnstileElement.value)
                    }
                }
                else {
                    //if not pass google reCaptcha, show the error
                    loading.value = false
                    status.value = "failed"
                    message.value = result["error_code"]

                    await loadTurnstileElement(turnstile, turnstileElement.value)
                }
            }
            catch (err) {
                //if not pass google reCaptcha, show the error
                loading.value = false
                status.value = "failed"
                message.value = err.message

                await loadTurnstileElement(turnstile, turnstileElement.value)
            }
            
            
        }

        const handleVerify = async () => {
            await store.dispatch('verifyemail', {email: username.value})

            status.value = store.getters.authStatus.status
            message.value = store.getters.authStatus.message

            if (store.getters.authStatus == "success") {
                message.value = store.getters.authError.message
                error.value = ''
                emailVerify = false
            }
            else {
                error.value = store.getters.authError
            }

        }
        

        //add script for google reCaptcha to template
        // onMounted(() => {
        //     insertReCaptchaScript("google_recaptcha")
        // })
        onMounted(async () => {
            try {
                // Load Turnstile script dynamically
                await loadTurnstileScript();
                await loadTurnstileElement(turnstile, turnstileElement.value)

            } catch (error) {
                console.error(error);
            }
        });

        onBeforeUnmount(() => {
            // Clean up Turnstile instance when the component is unmounted
            try {
                turnstile.remove()
            }
            catch (err) {
                console.error(err)
            }
        });

        return {loading, 
                username, 
                password, 
                message,
                status,
                handleLogin,  
                handleVerify,
                succeedMessage,
                error,
                breadcrumb_links,
                turnstileElement,
                }
    }
}
</script>

<style scoped>
input {
    display:block;
    margin: 10px 0;
    padding:10px;
    border-radius: 5px;
    border:1px solid #999;
    width: 25em;
    
}
#nav-tab button.active{
    border: 2px solid #c9ced6;
    color:#444;
    font-weight: bold;
}
#nav-tab button{
    color:grey;
}
a:active, a:focus, a:visited {
    color: #555;
    text-decoration: none;
}

</style>